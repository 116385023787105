import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

class AboutPage extends React.Component {
    render() {
      const siteTitle = 'About';
        
      return (
        <Layout title={siteTitle}>
        <Seo title="About page" />
        <article>
          <h1>Imprint</h1>
          <p>The following person is responsible for the content provided on this website:</p>
          <p>
            Alexander Schmidt<br/>
            Herderstrasse 31<br/>
            39108 Magdeburg<br/>
            Germany<br/>
          </p>
          <h1>About codingfreaks</h1>
          <p>
            codingfreaks started as the personal blog about different technical areas I (Alexander Schmidt) was interested in. 
            Over the years it was more and more influenced by my job as a co-founder and technical lead at <a href="https://devdeer.com" target="_blank" rel="noopener noreferrer">DEVDEER</a> located
            in Magdeburg (Germany). Now one could argue that codingfreaks became the official blog of DEVDEER. My co-workers
            helped me with some articles and a lot of those articles where in turn influenced by my experiences at DEVDEER.
          </p>
          <p>
            I mainly work as a consultant. In this role I help customers during transformations from classic architectures to 
            cloud environments. We (DEVDEER and I) are heavily concentrated on Microsoft Azure as a cloud platform.
          </p>
          <p>
            But codingfreaks is not just about blogging. We also try to share tools and source code with the community. <a href="https://github.com/codingfreaks" target="_blank" rel="noopener noreferrer">Github</a> is our main resource for doing it. 
            We have published packages on <a href="https://www.nuget.org/packages?q=codingfreaks" target="_blank" rel="noopener noreferrer">Nuget</a> and <a href="https://chocolatey.org/profiles/codingfreaks" target="_blank" rel="noopener noreferrer">chocolatey</a> too.
          </p>
          <p>
            My natural language is German. I tell you this because some grammatical or spelling issues may result from this fact. Another thing 
            to state out is that I'm kind of unusual when it comes to blogging. Normally blog posts tend to be short and crisp. That's not what you can expect
            from this site. I like to explain issues which are usually more complex and it would make no sense to give brief explanations.
            Instead I want to provide a deeper picture of certain things. Another effect this generates is that I cannot do blog posts at a very high speed. It
            simply takes time to take all the screenshots and go deeper.
          </p>
          <p>
            I recognized that some people find it simpler to see explanations in forms of videos. That is why I started a <a href="https://www.youtube.com/user/sprinter252" target="_blank" rel="noopener noreferrer">Youtube Channel</a> which sadly lives
            under a personal URL. To this day I could not figure out how to safely rename a channel but this is a different story.
          </p>
          <p>
            I hope my content helps people. Thats why I'm doing all this and I still like it. Have fun,
          </p>
          <p>
            Alex
          </p>
        </article>
      </Layout>
      )
    }
  }
  
  export default AboutPage